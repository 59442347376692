// Variables
$green: #0a0;
$blink-duration: 2.5s;
$blink-animation-type: linear;
$min-blink-opacity: 0.1;
$loading-offset: 25px;

@font-face {
	font-family: Terminal;
	src: url("./src/fonts/_terminal.ttf") format("truetype");

	// https://web.dev/font-display/
	font-display: swap;
}

.meeseOS-boot-splash {
	transition: 1s;
	width: 100%;
	height: 100%;

	body {
		margin: 0;
	}

	[data-letter-crap] {
		position: relative;
		white-space: pre;
		overflow: hidden;
		font-family: monospace;
		padding: 0;
		color: $green;
		width: 90%;
		max-width: 1200px;
		font-size: 10px;
		line-height: 10px;

		@media (width <= 800px) {
			font-size: 6.5px;
			line-height: 6.5px;
		}

		@media (width <= 600px) {
			font-size: 5px;
			line-height: 5px;
		}
	}
}

#loadingSeparator {
	border: 0;
	border-top: 1px dashed gray;
	width: 250px;
	border-image: radial-gradient(gray, transparent) 1;
}

// https://stackoverflow.com/a/16344389/6456163
.blinking {
	font-family: Terminal, monospace;
	font-size: 24px;
	color: $green;
	text-shadow: 0 0 5px $green;
	animation-name: blinker;
	animation-duration: $blink-duration;
	animation-timing-function: $blink-animation-type;
	animation-iteration-count: infinite;
}

@keyframes blinker {
	0% {
		opacity: 1;
	}

	50% {
		opacity: $min-blink-opacity;
	}

	100% {
		opacity: 1;
	}
}

@keyframes blinker {
	0% {
		opacity: 1;
	}

	50% {
		opacity: $min-blink-opacity;
	}

	100% {
		opacity: 1;
	}
}

@keyframes blinker {
	0% {
		opacity: 1;
	}

	50% {
		opacity: $min-blink-opacity;
	}

	100% {
		opacity: 1;
	}
}

.loadingPage {
	width: 100%;
	height: calc(100% + $loading-offset);
	top: 0;
	left: 0;
	text-align: center;
	z-index: 9999999999999999;
	color: #fff;
	background-color: #000;
	transition: 1s;

	.loadingContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		margin-top: -$loading-offset;

		h1 {
			font-family: Terminal, monospace;
			letter-spacing: 1.35px;
			text-shadow: 0 0 1.5px #fff;
		}
	}
}

#mouseMoved {
	display: none;
}

#loadingBar {
	font-family: monospace;
	font-size: 12px;
	line-height: 20px;
	width: 0;
	min-width: 0;
	max-width: 100%;
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	overflow: visible;
	margin-top: 0;
	margin-bottom: 0;
	color: #fff;
	background: $green;
	position: relative;
	display: inline-block;
	white-space: nowrap;
	box-shadow: 0 -3px 0 $green;
}

#loadingBar::before {
	content: "\00a0";
}

#loadingBarDiv {
	width: 75%;
	min-width: 200px;
	max-width: 800px;
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	background: #000;
	position: relative;
	display: inline-block;
	text-align: left;
	overflow: visible;
}
